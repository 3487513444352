import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const RemodelingContainer = () => {
  

  return (
    <Div >
      
      <Img src="/static/img/site_opening.png"/>
    </Div>
  );
};

RemodelingContainer.defaultProps = {};

const Div = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    background-color: #334768;
  }

`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 400px) {
    height: auto;
  }
`

export default RemodelingContainer;
